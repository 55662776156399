import api from '@/utils/api';
import Socket from '@/utils/services/Socket';

const { socket } = Socket.initialize();

// TODO: add success & error messages

export default class Database {
  static async getAll(collection, params) {
    try {
      const response = await api.get(`/${collection}`, { params: params });
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }

  static async getById(collection, id, params) {
    try {
      const response = await api.get(`/${collection}/${id}`, {
        params: params,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }

  static async create(collection, item) {
    try {
      const response = await api.post(`/${collection}`, item);
      socket.emit('update', collection);
      return response.status;
    } catch (err) {
      console.log(err);
    }
  }

  static async update(collection, id, item) {
    try {
      const { status } = await api.put(`/${collection}/${id}`, item);
      socket.emit('update', collection);
      return status;
    } catch (err) {
      console.log(err);
    }
  }

  static async delete(collection, id) {
    try {
      const { status } = await api.delete(`/${collection}/${id}`);
      return status;
    } catch (err) {
      console.log(err);
    }
  }

  static async upload(collection, file) {
    try {
      const formData = new FormData();
      formData.append('file', file, file.name);

      const { data, status } = await api.post(`/uploads/${collection}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (status === 200) return data;
    } catch (err) {
      console.log(err);
    }
  }

  static async getActivity(params) {
    try {
      const { status, data } = await api.get(
        '/users/current',
        { params }
      );
      return status === 200 ? data : [];
    } catch (err) {
      console.log(err);
    }
  }
}

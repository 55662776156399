import { io } from 'socket.io-client';

let instance = null;
export default class Socket {
  static initialize() {
    if (instance === null)
      instance = io(process.env.VUE_APP_PROD_SOCKET_URL, {
        transports: ['polling'],
      });
    return {
      socket: instance,
    };
  }
}